
@use "../libs/functions" as f;

.box {
    border-radius: f.size(border-radius);
    border: solid 2px f.palette(border);
    margin-bottom: f.size(element-margin);
    padding: 1.5em;

    > :last-child,
    > :last-child > :last-child,
    > :last-child > :last-child > :last-child {
        margin-bottom: 0;
    }

    &.alt {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
}