
@use "../libs/breakpoints" as b;
@use "../libs/functions" as f;

ul.pagination {
    cursor: default;
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;
        padding-left: 0;
        vertical-align: middle;

        > .page {
            transition: background-color #{f.duration(transition)} ease-in-out,
                color #{f.duration(transition)} ease-in-out;
            border-bottom: 0;
            border-radius: f.size(border-radius);
            display: inline-block;
            height: 1.5em;
            line-height: 1.5em;
            margin: 0 0.125em;
            min-width: 1.5em;
            padding: 0 0.5em;
            text-align: center;

            &:hover {
                background-color: f.palette(border-bg);
            }

            &.active {
                background-color: f.palette(accent);
            }
        }

        &:first-child {
            padding-right: 0.75em;
        }

        &:last-child {
            padding-left: 0.75em;
        }
    }

    @include b.breakpoint('<=xsmall') {
        li {
            &:nth-child(n+2):nth-last-child(n+2) {
                display: none;
            }

            .button {
                width: 100%;
            }

            &:first-child {
                width: calc(50% - 2px);
                text-align: left;
                padding-right: 0.325em;
            }

            &:last-child {
                width: calc(50% - 2px);
                text-align: right;
                padding-left: 0.325em;
            }
        }
    }
}
