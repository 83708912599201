
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;
@use "../libs/mixins" as m;

/* Form */
form {
    margin: 0 0 f.size(element-margin) 0;

    > :last-child {
        margin-bottom: 0;
    }

    > .fields {
        $gutter: f.size(element-margin) * 0.75;

        display: flex;
        flex-wrap: wrap;
        width: calc(100% + #{$gutter * 2});
        margin: ($gutter * -1) 0 f.size(element-margin) ($gutter * -1);

        > .field {
            flex-grow: 0;
            flex-shrink: 0;
            padding: $gutter 0 0 $gutter;
            width: calc(100% - #{$gutter * 1});

            &.half {
                width: calc(50% - #{$gutter * 0.5});
            }

            &.third {
                width: calc(#{calc(100% / 3)} - #{$gutter * calc(1 / 3)});
            }

            &.quarter {
                width: calc(25% - #{$gutter * 0.25});
            }
        }
    }

    @include b.breakpoint('<=xsmall') {
        > .fields {
            $gutter: f.size(element-margin) * 0.75;

            width: calc(100% + #{$gutter * 2});
            margin: ($gutter * -1) 0 f.size(element-margin) ($gutter * -1);

            > .field {
                padding: $gutter 0 0 $gutter;
                width: calc(100% - #{$gutter * 1});

                &.half {
                    width: calc(100% - #{$gutter * 1});
                }

                &.third {
                    width: calc(100% - #{$gutter * 1});
                }

                &.quarter {
                    width: calc(100% - #{$gutter * 1});
                }
            }
        }
    }
}

label {
    color: f.palette(fg-bold);
    display: block;
    font-family: f.font(family-heading);
    font-size: 0.8em;
    font-weight: f.font(weight-heading-bold);
    letter-spacing: f.font(kern-heading);
    margin: 0 0 f.size(element-margin) * 0.35 0;
    text-transform: uppercase;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
    appearance: none;
    background: f.palette(border-bg);
    border-radius: f.size(border-radius);
    border: none;
    border: solid 2px f.palette(border);
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;

    &:invalid {
        box-shadow: none;
    }

    &:focus {
        border-color: desaturate(lighten(f.palette(accent), 6), 3);
    }
}

select {
    background-image: f.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'><path d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='#{f.palette(border)}' /></svg>");
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    height: f.size(element-height);
    padding-right: f.size(element-height);
    text-overflow: ellipsis;

    option {
        color: f.palette(fg-bold);
        background: f.palette(bg);
    }

    &:focus {
        &::-ms-value {
            background-color: transparent;
        }
    }

    &::-ms-expand {
        display: none;
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: f.size(element-height);
}

textarea {
    padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;

    & + label {
        @include m.icon(false, solid);
        color: f.palette(fg);
        cursor: pointer;
        display: inline-block;
        font-size: 1em;
        font-family: f.font(family);
        text-transform: none;
        letter-spacing: 0;
        font-weight: f.font(weight);
        padding-left: f.size(element-height) * 0.6 + 0.75em;
        padding-right: 0.75em;
        position: relative;

        &:before {
            background: f.palette(border-bg);
            border-radius: f.size(border-radius);
            border: solid 2px f.palette(border);
            content: '';
            display: inline-block;
            font-size: 0.8em;
            height: f.size(element-height) * 0.75;
            left: 0;
            line-height: f.size(element-height) * 0.75;
            position: absolute;
            text-align: center;
            top: 0;
            width: f.size(element-height) * 0.75;
        }
    }

    &:checked + label {
        &:before {
            background: f.palette(fg-bold);
            border-color: f.palette(fg-bold);
            content: '\f00c';
            color: f.palette(bg);
        }
    }

    &:focus + label {
        &:before {
            border-color: f.palette(accent);
        }
    }
}

input[type="checkbox"] {
    & + label {
        &:before {
            border-radius: f.size(border-radius);
        }
    }
}

input[type="radio"] {
    & + label {
        &:before {
            border-radius: 100%;
        }
    }
}

::-webkit-input-placeholder {
    color: f.palette(fg-light) !important;
    opacity: 1.0;
}

:-moz-placeholder {
    color: f.palette(fg-light) !important;
    opacity: 1.0;
}

::-moz-placeholder {
    color: f.palette(fg-light) !important;
    opacity: 1.0;
}

:-ms-input-placeholder {
    color: f.palette(fg-light) !important;
    opacity: 1.0;
}