
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;

/* Actions */

ul.actions {
    display: flex;
    cursor: default;
    list-style: none;
    margin-left: f.size(element-margin) * -0.5;
    padding-left: 0;

    li {
        padding: 0 0 0 f.size(element-margin) * 0.5;
        vertical-align: middle;
    }

    &.special {
        justify-content: center;
        width: 100%;
        margin-left: 0;

        li {
            &:first-child {
                padding-left: 0;
            }
        }
    }

    &.stacked {
        flex-direction: column;
        margin-left: 0;

        li {
            padding: f.size(element-margin) * 0.65 0 0 0;

            &:first-child {
                padding-top: 0;
            }
        }
    }

    &.fit {
        width: calc(100% + #{f.size(element-margin) * 0.5});

        li {
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;

            > * {
                width: 100%;
            }
        }

        &.stacked {
            width: 100%;
        }
    }

    @include b.breakpoint('<=xsmall') {
        &:not(.fixed) {
            flex-direction: column;
            margin-left: 0;
            width: 100% !important;

            li {
                flex-grow: 1;
                flex-shrink: 1;
                padding: f.size(element-margin) * 0.5 0 0 0;
                text-align: center;
                width: 100%;

                > * {
                    width: 100%;
                }

                &:first-child {
                    padding-top: 0;
                }

                input[type="submit"],
                input[type="reset"],
                input[type="button"],
                button,
                .button {
                    width: 100%;

                    &.icon {
                        &:before {
                            margin-left: -0.5rem;
                        }
                    }
                }
            }
        }
    }
}