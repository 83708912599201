
@use "../libs/mixins" as m;
@use "../libs/functions" as f;

ol {
    list-style: decimal;
    margin: 0 0 f.size(element-margin) 0;
    padding-left: 1.25em;

    li {
        padding-left: 0.25em;
    }
}

ul {
    list-style: disc;
    margin: 0 0 f.size(element-margin) 0;
    padding-left: 1em;

    li {
        padding-left: 0.5em;
    }

    &.alt {
        list-style: none;
        padding-left: 0;

        li {
            border-top: solid 1px f.palette(border);
            padding: 0.5em 0;

            &:first-child {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
}

dl {
    margin: 0 0 f.size(element-margin) 0;

    dt {
        display: block;
        font-weight: f.font(weight-bold);
        margin: 0 0 f.size(element-margin) * 0.5 0;
    }

    dd {
        margin-left: f.size(element-margin);
    }
}