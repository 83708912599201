
@use "../libs/mixins" as m;

.icon {
    @include m.icon;
    border-bottom: none;
    position: relative;

    span {
        margin-left: 1rem;
    }

    > .label {
        display: none;
    }

    &:before {
        line-height: inherit;
    }

    &.solid {
        &:before {
            font-weight: 900;
        }
    }

    &.brands {
        &:before {
            font-family: 'Font Awesome 5 Brands';
        }
    }
}