
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;
@use "../libs/mixins" as m;

/* Features */

.features {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 f.size(element-margin) 0;

    article {
        @include m.padding(1.75em, 1.75em);
        background-color: desaturate(lighten(f.palette(bg), 3), 1.5);
        border-radius: f.size(border-radius);
        margin: f.size(section-spacing, large) * 0.5 f.size(section-spacing, large) f.size(section-spacing, large) * 0.5 0;
        width: calc(50% - #{f.size(section-spacing, large) * 0.5});

        &:nth-child(2n) {
            margin-right: 0;
        }

        .image {
            border-radius: f.size(border-radius) f.size(border-radius) 0 0;
            display: block;
            margin-bottom: 1.75em;
            margin-left: -1.75em;
            margin-top: -1.75em;
            position: relative;
            width: calc(100% + #{3.5em});

            img {
                border-radius: f.size(border-radius) f.size(border-radius) 0 0;
                width: 100%;
            }
        }
    }

    @include b.breakpoint('<=medium') {
        article {
            margin: f.size(section-spacing, medium) * 0.5 f.size(section-spacing, medium) f.size(section-spacing, medium) * 0.5 0;
            width: calc(50% - #{f.size(section-spacing, medium) * 0.5});
        }
    }

    @include b.breakpoint('<=small') {
        article {
            @include m.padding(1.5em, 1.5em);
            margin: f.size(section-spacing, small) * 0.5 f.size(section-spacing, small) f.size(section-spacing, small) * 0.5 0;
            width: calc(50% - #{f.size(section-spacing, small) * 0.5} - 1px);

            .image {
                margin-bottom: 1.5em;
                margin-left: -1.5em;
                margin-top: -1.5em;
                width: calc(100% + #{3em});
            }
        }
    }

    @include b.breakpoint('<=xsmall') {
        display: block;
        article {
            width: 100%;
            margin: 0 0 f.size(element-margin) 0 !important;
        }
    }
}