
@use "functions" as f;

/// Makes an element's :before pseudoelement a FontAwesome icon.
/// @param {string} $content Optional content value to use.
/// @param {string} $category Optional category to use.
/// @param {string} $where Optional pseudoelement to target (before or after).
@mixin icon($content: false, $category: regular, $where: before) {

    text-decoration: none;

    &:#{$where} {

        @if $content {
            content: $content;
        }

        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        text-transform: none !important;

        @if ($category == brands) {
            font-family: 'Font Awesome 5 Brands';
        } @else if ($category == solid) {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        } @else {
            font-family: 'Font Awesome 5 Free';
            font-weight: 400;
        }
    }
}

/// Applies padding to an element, taking the current element-margin value into account.
/// @param {mixed} $tb Top/bottom padding.
/// @param {mixed} $lr Left/right padding.
/// @param {list} $pad Optional extra padding (in the following order top, right, bottom, left)
/// @param {bool} $important If true, adds !important.
@mixin padding($tb, $lr, $pad: (0,0,0,0), $important: null) {

    @if $important {
        $important: '!important';
    }

    $x: 0.1em;

    @if unit(f.size(element-margin)) == 'rem' {
        $x: 0.1rem;
    }

    padding: ($tb + nth($pad,1)) ($lr + nth($pad,2)) max($x, #{$tb - f.size(element-margin) + nth($pad, 3)}) ($lr + nth($pad,4)) #{$important};
}

