
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;
@use "../libs/mixins" as m;

/* Menu */

#page-wrapper {
    transition: filter 0.25s ease;
}

#menu {
    align-items: center;
    display: flex;
    justify-content: center;
    pointer-events: none;
    transition: opacity #{f.duration(menu)} ease', 'visibility #{f.duration(menu)};
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: var(--colour-cream-transparent);
    cursor: default;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    text-align: center;
    top: 0;
    visibility: hidden;
    width: 100%;

    .inner {
        @include m.padding(2.5em, 1.5em);
        transform: translateY(0.5em);
        transition: opacity #{f.duration(menu)} ease, transform #{f.duration(menu)} ease;
        -webkit-overflow-scrolling: touch;
        background: var(--colour-cream);
        border-radius: f.size(border-radius);
        display: block;
        max-width: 100%;
        opacity: 0;
        position: relative;
        width: 18em;
    }

    .major {
        color: black;
    }

    h2 {
        border-bottom: solid 2px f.palette(border);
        padding-bottom: 1em;
    }

    .close {
        background-image: url('../../images/close.svg');
        background-position: 75% 25%;
        background-repeat: no-repeat;
        background-size: 2em 2em;
        border: 0;
        content: '';
        display: block;
        height: 4em;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        text-indent: 4em;
        top: 0;
        width: 4em;
        color: black;
    }

    .links {
        list-style: none;
        margin-bottom: (f.size(element-margin) - 0.5em);
        padding: 0;

        li {
            padding: 0;

            a {
                border-radius: f.size(border-radius);
                border: 0;
                display: block;
                font-family: f.font(family-heading);
                font-size: 0.8em;
                font-weight: bold;
                letter-spacing: f.font(kern-heading);
                line-height: 1.85em;
                padding: 0.75em 0;
                text-transform: uppercase;
                color: black;

                &:hover {
                    background: var(--colour-cream-darker);
                }
            }
        }
    }

    @include b.breakpoint('<=small') {
        .inner {
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            .close {
                background-size: 1.5em 1.5em;
            }
        }
    }
}

body.is-menu-visible {
    #page-wrapper {
        filter: blur(1.5px);
    }

    #menu {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;

        .inner {
            transform: translateY(0);
            opacity: 1;
        }
    }
}