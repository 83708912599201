
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;
@use"../libs/mixins" as m;

@import url('https://fonts.googleapis.com/css?family=Raleway:200,700|Source+Sans+Pro:300,600,300italic,600italic');

body, input, select, textarea {
    color: f.palette(fg);
    font-family: f.font(family);
    font-size: 16.5pt;
    font-weight: f.font(weight);
    line-height: 1.65;

    @include b.breakpoint('<=xlarge') {
        font-size: 13pt;
    }

    @include b.breakpoint('<=large') {
        font-size: 12pt;
    }

    @include b.breakpoint('<=medium') {
        font-size: 12pt;
    }

    @include b.breakpoint('<=small') {
        font-size: 12pt;
    }

    @include b.breakpoint('<=xsmall') {
        font-size: 12pt;
    }
}

a {
    transition: color #{f.duration(transition)} ease-in-out, border-bottom-color #{f.duration(transition)} ease-in-out;
    border-bottom: dotted 1px f.palette(fg-light);
    color: f.palette(fg-bold);
    text-decoration: none;

    &:hover {
        border-bottom-color: transparent;
        color: f.palette(fg-bold) !important;
    }

    &.special:not(.button) {
        @include m.icon(false, solid);
        border-bottom: 0;
        display: block;
        font-family: f.font(family-heading);
        font-size: 0.8em;
        font-weight: f.font(weight-heading-bold);
        letter-spacing: f.font(kern-heading);
        margin: 0 0 f.size(element-margin) 0;
        text-transform: uppercase;

        &:before {
            transition: background-color #{f.duration(transition)} ease-in-out;
            border-radius: 100%;
            border: solid 2px f.palette(border);
            content: '\f105';
            display: inline-block;
            font-size: 1.25em;
            height: 2em;
            line-height: 1.75em;
            margin-right: 0.85em;
            text-align: center;
            text-indent: 0.15em;
            vertical-align: middle;
            width: 2em;
        }

        &:hover {
            &:before {
                background-color: f.palette(border-bg);
            }
        }

        &:active {
            &:before {
                background-color: f.palette(border2-bg);
            }
        }
    }
}

strong, b {
    color: f.palette(fg-bold);
    font-weight: f.font(weight-bold);
}

em, i {
    font-style: italic;
}

p {
    margin: 0 0 f.size(element-margin) 0;
}

h1, h2, h3, h4, h5, h6 {
    color: f.palette(fg-bold);
    font-family: f.font(family-heading);
    font-weight: f.font(weight-heading-bold);
    letter-spacing: f.font(kern-heading);
    margin: 0 0 f.size(element-margin) * 0.5 0;
    text-transform: uppercase;

    a {
        color: inherit;
        text-decoration: none;
        border-bottom: 0;
    }

    span {
        font-weight: f.font(weight-heading);
    }

    &.major {
        padding-bottom: 1em;
        border-bottom: solid 2px f.palette(border);
    }
}

h2 {
    font-size: 1.2em;
}

h3 {
    font-size: 0.9em;
}

h4 {
    font-size: 0.7em;
}

h5 {
    font-size: 0.7em;
}

h6 {
    font-size: 0.7em;
}

@include b.breakpoint('<=small') {
    h2 {
        font-size: 1em;
    }
    h3 {
        font-size: 0.8em
    }
}

sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

blockquote {
    $f_size: calc(#{f.size(element-margin)} / 4);
    border-left: solid 4px f.palette(border);
    font-style: italic;
    margin: 0 0 f.size(element-margin) 0;
    padding: $f_size 0 $f_size f.size(element-margin);
}

code {
    background: f.palette(border-bg);
    border-radius: f.size(border-radius);
    border: solid 2px f.palette(border);
    font-family: f.font(family-fixed);
    font-size: 0.9em;
    margin: 0 0.25em;
    padding: 0.25em 0.65em;
}

pre {
    -webkit-overflow-scrolling: touch;
    font-family: f.font(family-fixed);
    font-size: 0.9em;
    margin: 0 0 f.size(element-margin) 0;

    code {
        display: block;
        line-height: 1.75em;
        padding: 1em 1.5em;
        overflow-x: auto;
    }
}

hr {
    border: 0;
    border-bottom: solid 2px f.palette(border);
    margin: f.size(element-margin) * 1.25 0;

    &.major {
        margin: f.size(element-margin) * 2 0;
    }
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}