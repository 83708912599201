
@use "../libs/html-grid" as g;
@use "../libs/breakpoints" as b;

.row {
    @include g.html-grid(1.75em);

    @include b.breakpoint('<=xlarge') {
        @include g.html-grid(1.75em, xlarge);
    }

    @include b.breakpoint('<=large') {
        @include g.html-grid(1.75em, large);
    }

    @include b.breakpoint('<=medium') {
        @include g.html-grid(1.75em, medium);
    }

    @include b.breakpoint('<=small') {
        @include g.html-grid(1.25em, small);
    }

    @include b.breakpoint('<=xsmall') {
        @include g.html-grid(1.25em, xsmall);
    }

    @include b.breakpoint('<=xxsmall') {
        @include g.html-grid(1.25em, xxsmall);
    }
}