
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;

@mixin clip-border($direction, $type: 'bottom', $height_diff: 75px) {
    .clip-#{$direction}-#{$type} {
        @if $type == 'both' {
            @if $direction == 'ltr' {
                clip-path: polygon(0 0, 100% calc(0% + #{$height_diff}), 100% calc(100% - #{$height_diff}), 0% 100%);
            } @else {
                clip-path: polygon(0 calc(0% + #{$height_diff}), 100% 0, 100% 100%, 0 calc(100% - #{$height_diff}));
            }
            margin-top: -#{$height_diff} !important;
            margin-bottom: -#{$height_diff} !important;
            padding: #{$height_diff} 0;
        }
        @else if $type == 'bottom' {
            @if $direction == 'ltr' {
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$height_diff}), 0 100%);
            } @else {
                clip-path: polygon(0 0, 0 calc(100% - #{$height_diff}), 100% 100%, 100% 0);
            }
            margin-bottom: -#{$height_diff};
            padding-bottom: #{$height_diff};
        }
        @else if $type == 'top' {
            @if $direction == 'ltr' {
                clip-path: polygon(calc(100% - #{$height_diff}) 100%, 0 100%, 100% 100%, 100% 0);
            } @else {
                clip-path: polygon(0 calc(100% - #{$height_diff}), 0 100%, 100% 100%, 100% 0);
            }
            margin-top: -#{$height_diff};
            padding-top: #{$height_diff};
        }
    }
}

@include clip-border('ltr', 'both', f.misc(clip-path-diff));
@include clip-border('rtl', 'both', f.misc(clip-path-diff));
@include clip-border('ltr', 'bottom', f.misc(clip-path-diff));
@include clip-border('rtl', 'bottom', f.misc(clip-path-diff));
@include clip-border('ltr', 'top', f.misc(clip-path-diff));
@include clip-border('rtl', 'top', f.misc(clip-path-diff));