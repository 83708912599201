
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    appearance: none;
    transition: background-color #{f.duration(transition)} ease-in-out;
    background-color: transparent;
    border-radius: f.size(border-radius);
    border: 0;
    box-shadow: inset 0 0 0 2px f.palette(border);
    color: f.palette(fg-bold) !important;
    cursor: pointer;
    display: inline-block;
    font-family: f.font(family-heading);
    font-size: 0.8em;
    font-weight: f.font(weight-heading-bold);
    height: 3.75em;
    letter-spacing: f.font(kern-heading);
    line-height: 3.75em;
    padding: 0 2.25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        background-color: f.palette(border-bg);
    }

    &:active {
        background-color: f.palette(border2-bg);
    }

    &.icon {
        &:before {
            margin-right: 0.5em;
            color: f.palette(fg-light);
        }
    }

    &.primary {
        background-color: f.palette(accent);
        box-shadow: none;

        &:hover {
            background-color: desaturate(lighten(f.palette(accent), 3), 1.5);
        }

        &:active {
            background-color: saturate(darken(f.palette(accent), 3), 1.5);
        }

        &.icon {
            &:before {
                color: mix(f.palette(fg-bold),f.palette(accent), 25%);
            }
        }
    }

    &.fit {
        width: 100%;
    }

    &.small {
        font-size: 0.6em;
    }

    &.large {
        font-size: 1em;
    }

    &.disabled,
    &:disabled {
        opacity: 0.25;
    }

    @include b.breakpoint('<=xsmall') {
        padding: 0;
    }
}