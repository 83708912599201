
@use "../libs/breakpoints" as b;

//.about-us {
//     .features article img {
//        margin: -1.75em 3em 1.75em -1.75rem !important;
//    }
//}

.fb-page {
    width: 100%;
}

.line-up {
    padding: 0.5rem;
    column-count: 2;
    @include b.breakpoint('<=small') {
        column-count: 1;
    }
    @include b.breakpoint('<=xsmall') {
        column-count: 1;
    }
    dt {
        float: left;
        clear: left;
        width: 3rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    dt::after {
        content: ":";
    }
    dd {
        margin: 0 0 0.5rem 6rem;
        padding: 0 0 0.5rem 0;
    }
}

#media-gallery {
    font-size: 12pt;
}