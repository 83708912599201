
@use "../libs/breakpoints" as b;

.google-map {

    position: relative;
    padding-bottom: 50%; // This is the aspect ratio
    height: 0;
    overflow: hidden;

    @include b.breakpoint('<=medium') {
        padding-bottom: 60%; // This is the aspect ratio
    }

    @include b.breakpoint('<=small') {
        padding-bottom: 100%; // This is the aspect ratio
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
