
@use "../libs/vars" as vars;
@use "../libs/functions" as f;
@use "../libs/mixins" as m;
@use "../libs/breakpoints" as b;

/// Sets the colors of the wrapper's top/bottom edges.
/// @param {string} $color Color.
@mixin wrapper-edges-color($color: black) {

    &:before, &:after {
        background: $color;
        -webkit-mask-image: f.svg-url(vars.$svg_bg);
        mask-image: f.svg-url(vars.$svg_bg);
    }
    &:before {
        box-shadow: inset 0 -1px 0 0 $color, 0 1px 0 0 $color;
    }
    &:after {
        box-shadow: inset 0 -1px 0 0 $color, 0 1px 0 0 $color;
    }
}

#wrapper {
    > header {
        @include m.padding(7.5em, 0, (3.5em, 0,f.size(wrapper-edges, large) * -0.5, 0));

        .inner {
            margin: 0 auto;
            width: f.size(inner);
        }

        h2 {
            border-bottom: solid 2px f.palette(border);
            font-size: 2em;
            margin-bottom: f.size(element-margin) * 0.4;
            padding-bottom: f.size(element-margin) * 0.2;
        }

        p {
            font-family: f.font(family-heading);
            font-size: 1em;
            font-weight: f.font(weight-heading);
            letter-spacing: f.font(kern-heading);
            line-height: 2;
            text-transform: uppercase;
        }
    }

    @include b.breakpoint('<=large') {
        > header {
            @include m.padding(5em, 0, (4em, 0,f.size(wrapper-edges, large) * 0.5, 0));
            background-color: f.palette(bg);
            background-image: linear-gradient(to top, transparentize(f.palette(bg), 0.2), transparentize(f.palette(bg), 0.2)), url('../../images/bg.jpg');
            background-size: auto, cover;
            background-position: center, 0 30%;
            margin-bottom: f.size(wrapper-edges, large) * -1;
        }
    }

    @include b.breakpoint('<=medium') {
        > header {
            @include m.padding(7em, 3em, (4em, 0,f.size(wrapper-edges, medium) * 0.5, 0));
            background-size: auto, cover;
            background-position: center, 0 0;
            margin-bottom: f.size(wrapper-edges, medium) * -1;
            .inner {
                width: 100%;
            }
        }
    }

    @include b.breakpoint('<=small') {
        > header {
            @include m.padding(3.75em, 2em, (2.75em, 0, f.size(wrapper-edges, small) * 0.5, 0));
            background-size: auto, 125%;
            margin-bottom: f.size(wrapper-edges, small) * -1;

            h2 {
                font-size: 1.25em;
            }
            p {
                font-size: 0.8em;
            }
        }
    }
}

.wrapper {
    background-color: f.palette(bg);
    //margin: f.size(wrapper-edges, large) 0;
    position: relative;
    //@include wrapper-edges-color(f.palette(bg));

    //&:before, &:after {
    //    background-repeat: no-repeat;
    //    background-size: 100% 100%;
    //    content: '';
    //    display: block;
    //    height: f.size(wrapper-edges, large);
    //    position: absolute;
    //    width: 100%;
    //}

    //&:before {
    //    left: 0;
    //    top: f.size(wrapper-edges, large) * -1;
    //}
    //
    //&:after {
    //    transform: scaleY(-1);
    //    bottom: f.size(wrapper-edges, large) * -1;
    //    left: 0;
    //}

    //&.alt {
    //    &:before {
    //        transform: scaleY(-1);
    //    }
    //    &:after {
    //        transform: scaleY(-1) scaleX(-1);
    //    }
    //}

    .inner {
        @include m.padding(3em, 0);
        margin: 0 auto;
        width: f.size(inner);
    }

    //@for $i from 2 through f.misc(max-wrapper-styles) {
    //    $j: 3 * ($i - 1);
    //    $color: desaturate(lighten(f.palette(bg), $j), $j * 0.5);
    //
    //    &.style#{$i} {
    //        background-color: $color;
    //        //@include wrapper-edges-color($color);
    //    }
    //}

    &.style1 {
        background: linear-gradient(60deg, var(--colour-blue) 0%, var(--colour-blue-darker) 100%);
    }

    &.style2 {
        background: linear-gradient(60deg, var(--colour-red) 0%, var(--colour-red-darker) 100%);
    }

    &.style3 {
        background: linear-gradient(60deg, var(--colour-green) 0%, var(--colour-green-darker) 100%);
    }

    &.style4 {
        background: linear-gradient(60deg, var(--colour-orange) 0%, var(--colour-orange-darker) 100%);
    }

    .image {
        border-radius: 100%;
        margin: 0 f.size(section-spacing, large)f.size(element-margin) 0;
        width: 22em;
        overflow: hidden;
        -ms-flex: 1;

        img {
            border-radius: 100%;
            width: 100%;
        }
    }

    &.spotlight {
        //@include wrapper-edges-color(f.palette(accent));
        background-color: f.palette(accent);

        .inner {
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        .content {
            width: 100%;
            -ms-flex: 2;
        }

        &:nth-child(2n - 1) {
            .inner {
                flex-direction: row-reverse;
                text-align: right;
            }

            .image {
                margin: 0 0 f.size(element-margin)f.size(section-spacing, large);
            }
        }

        @for $i from 2 through f.misc(max-wrapper-styles) {
            $j: 3 * ($i - 1);
            $color: saturate(darken(f.palette(accent), $j), $j * 0.5);

            &.style#{$i} {
                background-color: $color;
                //@include wrapper-edges-color($color);
            }
        }
    }

    @include b.breakpoint('<=medium') {
        margin: f.size(wrapper-edges, medium) 0;

        &:before, &:after {
            height: f.size(wrapper-edges, medium);
        }

        &:before {
            top: f.size(wrapper-edges, medium) * -1;
        }

        &:after {
            bottom: f.size(wrapper-edges, medium) * -1;
            left: 0;
        }

        .inner {
            @include m.padding(3em, 3em);
            width: 100%;
        }

        .image {
            margin: 0 f.size(section-spacing, medium)f.size(element-margin) 0;
            width: 32em;
        }

        &:nth-child(2n - 1) {
            .image {
                margin: 0 0 f.size(element-margin)f.size(section-spacing, medium);
            }
        }
    }

    @include b.breakpoint('<=small') {
        margin: f.size(wrapper-edges, small) 0;

        &:before, &:after {
            height: f.size(wrapper-edges, small);
        }

        &:before {
            top: f.size(wrapper-edges, small) * -1;
        }

        &:after {
            bottom: f.size(wrapper-edges, small) * -1;
            left: 0;
        }

        .inner {
            @include m.padding(2em, 2em);
        }

        &.spotlight {
            .inner {
                align-items: flex-start;
            }
        }

        .image {
            width: 19em;
            margin: 0 f.size(section-spacing, small)f.size(element-margin) 0;
        }

        &:nth-child(2n - 1) {
            .image {
                margin: 0 0 f.size(element-margin)f.size(section-spacing, small);
            }
        }
    }

    @include b.breakpoint('<=xsmall') {
        &.spotlight {
            .inner {
                display: block;
            }
        }

        .image {
            margin: 0 0 f.size(element-margin) * 0.5 0 !important;
            max-width: 85%;
            width: 12em;
        }
    }

    @include b.breakpoint('<=xxsmall') {
        .inner {
            @include m.padding(2em, 1.5em);
        }
    }
}