
ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;
        padding: 0 1em 0 0;

        &:last-child {
            padding-right: 0;
        }

        .icon {
            &:before {
                font-size: 1.25em;
            }
        }
    }
}