
@use "../libs/functions" as f;
@use"../libs/mixins" as m;
@use "../libs/breakpoints" as b;

/* Banner */

#banner {

    @include m.padding(10em, 0, (0, 0, f.size(wrapper-edges, large) * -0.5, 0));

    @include b.breakpoint('<=small') {
        padding: 4rem 1rem 9rem 1rem !important;
    }

    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--bg-image-url);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .inner {
        margin: 0 auto;
        width: f.size(inner);
    }

    .logo {
        transition: opacity 2s ease, transform 1s ease;
        transform: translateY(0);
        opacity: 1;
        margin: 0 0 f.size(element-margin) * 0.65 0;

        .icon {
            border-radius: 100%;
            border: solid 2px f.palette(border);
            cursor: default;
            display: inline-block;
            font-size: 2em;
            height: 2.25em;
            line-height: 2.25em;
            text-align: center;
            width: 2.25em;
        }
    }

    h1 {
        transition: opacity 0.5s ease, transform 0.5s ease, filter 0.25s ease;
        transform: translateX(0);
        transition-delay: 0.65s;
        filter: blur(0);
        opacity: 1;
        border-bottom: solid 2px f.palette(border);
        font-size: 2.25em;
        margin-bottom: f.size(element-margin) * 0.4;
        padding-bottom: f.size(element-margin) * 0.2;
        position: absolute;
        text-shadow: 1px 3px 4px rgba(0,0,0,0.95);
    }

    p {
        transition: opacity 0.5s ease, transform 0.5s ease, filter 0.25s ease;
        transform: translateX(0);
        transition-delay: 0.8s;
        filter: blur(0);
        opacity: 1;
        font-family: f.font(family-heading);
        font-size: 1em;
        font-weight: f.font(weight-heading);
        letter-spacing: f.font(kern-heading);
        line-height: 2;
        text-transform: uppercase;
    }

    .inner-small {

        display: flex;
        flex-direction: column;
        justify-content: center;

        .logo {
            width: 20rem;
            flex: 1
        }

        h1 {
            margin-left: 22rem;
        }

        @include b.breakpoint('<=medium') {
            display: block;
            * {
                display: table;
            }
            h1 {
                margin-left: 0;
            }
        }
    }

    @include b.breakpoint('<=large') {
        @include m.padding(7em, 0, (0, 0,f.size(wrapper-edges, large) * 0.5, 0));
        background-color: f.palette(bg);
        background-size: auto, cover;
        background-position: center, center;
        margin-bottom: f.size(wrapper-edges, large) * -1;
    }

    @include b.breakpoint('<=medium') {
        @include m.padding(12em, 3em, (0, 0, f.size(wrapper-edges, medium) * 0.5, 0));
        margin-bottom: f.size(wrapper-edges, medium) * -1;
        .inner {
            width: 100%;
        }
    }

    @include b.breakpoint('<=small') {
        @include m.padding(5em, 2em, (0, 0, f.size(wrapper-edges, small) * 0.5, 0));

        margin-bottom: f.size(wrapper-edges, small) * -1;

        .logo {
            margin: 0 0 f.size(element-margin) * 0.5 0;

            .icon {
                font-size: 1.5em;
            }
        }

        h2 {
            font-size: 1.5em;
        }

        p {
            font-size: 0.8em;
        }
    }

    body.is-preload & {
        .logo {
            transform: translateY(0.5em);
            opacity: 0;
        }

        h2 {
            opacity: 0;
            transform: translateX(0.25em);
            filter: blur(2px);
        }

        p {
            opacity: 0;
            transform: translateX(0.5em);
            filter: blur(2px);
        }
    }
}