
@use "../libs/functions" as f;
@use "../libs/breakpoints" as b;

// MSIE: Required for IEMobile.
@-ms-viewport {
    width: #{device-width};
}

// MSIE: Prevents scrollbar from overlapping content.
body {
    -ms-overflow-style: scrollbar;
}

// Ensures page width is always >=320px.
@include b.breakpoint('<=xsmall') {
    html, body {
        min-width: 320px;
    }
}

// Set box model to border-box.
// Based on scss-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    background: linear-gradient(60deg, var(--colour-footer-grey) 0%, var(--colour-footer-dark-grey) 100%);
    // Stops initial animations until page loads.
    &.is-preload {
        *, *:before, *:after {
            animation: none !important;
            transition: none !important;
        }
    }

}

.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}