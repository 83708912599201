:root {
    /* Colours based on progress pride flag */
    --colour-red: rgb(228, 3, 3);
    --colour-red-darker: rgb(141, 3, 3);
    --colour-orange: rgb(255, 140, 0);
    --colour-orange-darker: rgb(168, 94, 0);
    --colour-yellow: rgb(255, 255, 0);
    --colour-green: rgb(0, 128, 38);
    --colour-green-darker: rgb(0, 68, 20);
    --colour-blue: rgb(36, 64, 142);
    --colour-blue-darker: rgb(24, 41, 93);
    --colour-purple: rgb(115, 41, 130);

    --colour-cream: rgb(252, 233, 206);
    --colour-pink: rgb(255, 175, 200);
    --colour-light-blue: rgb(116, 215, 238);
    --colour-brown: rgb(97, 57, 21);

    --colour-cream-transparent: rgba(252, 233, 206, 0.5);
    --colour-cream-darker: rgb(133, 123, 107);

    --colour-footer-grey: rgb(96, 96, 96);
    --colour-footer-copyright: rgb(35, 35, 35);
    --colour-footer-copyright-hover: rgb(114, 114, 114);
    --colour-footer-dark-grey: rgb(38, 38, 38);

    //--bg-red: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");
    //--bg-orange: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");
    //--bg-yellow: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");
    //--bg-green: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");
    //--bg-blue: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");
    //--bg-purple: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' /%3E%3C/svg%3E");

    //--rainbow-gradient: linear-gradient(
    //    -45deg,
    //    var(--colour-red) 0%,
    //    var(--colour-orange) 16.66%,
    //    var(--colour-yellow) 33.32%,
    //    var(--colour-green) 49.98%,
    //    var(--colour-blue) 66.64%,
    //    var(--colour-purple) 83.3%,
    //    var(--colour-red) 100%
    //);
}