
@use "../libs/mixins" as m;
@use "../libs/breakpoints" as b;
@use "../libs/functions" as f;

ul.contact {

    list-style: none;
    padding: 0;

    li {
        @include m.icon;
        margin: f.size(element-margin) * 1.25 0 0 0;
        padding: 0 0 0 3.25em;
        position: relative;

        &:before {
            border-radius: 100%;
            border: solid 2px f.palette(border);
            display: inline-block;
            font-size: 0.8em;
            height: 2.5em;
            left: 0;
            line-height: 2.35em;
            position: absolute;
            text-align: center;
            top: 0;
            width: 2.5em;
        }
    }

    @include b.breakpoint('<=small') {
        li {
            margin: f.size(element-margin) * 0.75 0 0 0;
        }
    }
}