
@use "../libs/mixins" as m;
@use "../libs/functions" as f;

.table-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

table {
    margin: 0 0 f.size(element-margin) 0;
    width: 100%;

    tbody {
        tr {
            border: solid 1px f.palette(border);
            border-left: 0;
            border-right: 0;

            &:nth-child(2n + 1) {
                background-color: f.palette(border-bg);
            }
        }
    }

    td {
        padding: 0.75em 0.75em;
    }

    th {
        color: f.palette(fg-bold);
        font-size: 0.9em;
        font-weight: f.font(weight-bold);
        padding: 0 0.75em 0.75em 0.75em;
        text-align: left;
    }

    thead {
        border-bottom: solid 2px f.palette(border);
    }

    tfoot {
        border-top: solid 2px f.palette(border);
    }

    &.alt {
        border-collapse: separate;

        tbody {
            tr {
                td {
                    border: solid 1px f.palette(border);
                    border-left-width: 0;
                    border-top-width: 0;

                    &:first-child {
                        border-left-width: 1px;
                    }
                }

                &:first-child {
                    td {
                        border-top-width: 1px;
                    }
                }
            }
        }

        thead {
            border-bottom: 0;
        }

        tfoot {
            border-top: 0;
        }
    }
}