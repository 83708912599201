
@use "../libs/functions" as f;

.image {
    border-radius: f.size(border-radius);
    border: 0;
    display: inline-block;
    position: relative;

    img {
        border-radius: f.size(border-radius);
        display: block;
    }

    &.left,
    &.right {
        max-width: 40%;

        img {
            width: 100%;
        }
    }

    &.left {
        float: left;
        padding: 0 1.5em 1em 0;
        top: 0.25em;
    }

    &.right {
        float: right;
        padding: 0 0 1em 1.5em;
        top: 0.25em;
    }

    &.fit {
        display: block;
        margin: 0 0 f.size(element-margin) 0;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &.main {
        display: block;
        margin: 0 0 f.size(element-margin) * 1.5 0;
        width: 100%;

        img {
            width: 100%;
        }
    }
}


.logo-wrap .image {
    padding: 1rem;
    .logo {
        object-position: 50% 50%;
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
}