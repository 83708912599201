
@use "../libs/functions" as f;
@use "../libs/mixins" as m;
@use "../libs/breakpoints" as b;

/* Header */

#header {

    display: flex;
    transition: background-color f.duration(transition) ease-in-out;
    background-color: var(--colour-cream);
    height: 3.5em;
    left: 0;
    line-height: 3.5em;
    padding: 0 1.25em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: f.misc(z-index-base);

    .logo {
        transition: opacity #{f.duration(transition)} ease-in-out, visibility #{f.duration(transition)};
        opacity: 1;
        visibility: visible;
        svg {
            margin-top: 5px;
            width: 50px;
            height: 50px;
        }
    }

    h1 {
        transition: opacity #{f.duration(transition)} ease-in-out, visibility #{f.duration(transition)};
        border-bottom: 0;
        font-size: 0.8em;
        margin-bottom: 0;
        opacity: 1;
        visibility: visible;
        color: black;
        a {
            border: 0;
        }
    }

    nav {
        font-family: f.font(family-heading), sans-serif;
        font-size: 0.8em;
        font-weight: f.font(weight-heading-bold);
        height: 3em;
        letter-spacing: f.font(kern-heading);
        line-height: 3em;
        position: absolute;
        right: 0.7em;
        text-transform: uppercase;
        top: 0.7em;

        a {
            border: 0;
            display: inline-block;
            padding: 0 1em;
            color: black;

            &:before {
                float: right;
                margin-left: 0.75em;
            }
        }

        a[href="#menu"],
        .go-fund-me-button {
            @include m.icon(false, solid);
            transition: background-color #{f.duration(transition)} ease-in-out;
            border-radius: f.size(border-radius);
            box-shadow: inset 0 0 0 2px black;
            padding: 0 1.35em;

            background-color: black;
            color: white;

            &:before {
                content: '\f0c9';
                line-height: inherit;
            }

            //&:active {
            //    background-color: f.palette(border2-bg);
            //}
        }

        .go-fund-me-button {

            &:before {
                content: '';
            }
        }
    }

    &.alt {
        background-color: transparent;

        h1, .logo {
            opacity: 0;
            visibility: hidden;
        }
    }

    @include b.breakpoint('<=small') {
        height: 2.75em;
        line-height: 2.75em;

        .logo {
            svg {
                margin-top: 4px;
                width: 40px;
                height: 40px;
            }
        }

        nav {
            top: 0;
            right: 0;
            height: inherit;
            line-height: inherit;

            a {
                height: inherit;
                line-height: inherit;

                &[href="#menu"] {
                    box-shadow: none;
                    padding: 0 1em;
                    border-radius: 0;

                    &:hover, &:active {
                        background-color: inherit;
                    }
                }
            }
        }
    }

    @include b.breakpoint('<=xsmall') {

        .logo {
            svg {
                margin-top: 2px;
                width: 35px;
                height: 35px;
            }
        }

        nav {
            a {
                &[href="#menu"] {
                    width: 4em;
                    white-space: nowrap;
                    text-indent: 4em;
                    position: relative;

                    &:before {
                        width: inherit;
                        position: absolute;
                        top: 0;
                        left: 0;
                        text-indent: 0;
                        text-align: right;
                        margin-left: 0;
                        padding-right: 1.25em;
                    }
                }
            }
        }
    }
}