
@use "../libs/functions" as f;
@use "../libs/mixins" as m;
@use "../libs/breakpoints" as b;

/* Footer */

#footer {
    .inner {
        @include m.padding(5em, 0);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        width: f.size(inner);

        > * {
            width: 100%;
        }

        form {
            margin: 0 f.size(section-spacing, large) 0 0;
            width: calc(50% - #{f.size(section-spacing, large) * 0.5});
        }

        .contact {
            display: flex;
            align-content: center;
            align-items: center;
            @include b.breakpoint('<=small') {
                display: block;
                align-content: unset;
                align-items: unset;
                li {
                    padding-left: 0.5rem;
                }
            }
            li:first-child {
                padding-left: 0.5rem;
            }
        }

        .icon {
            font-weight: bold;
        }

        .icon:hover {
            text-shadow: 0 0 10px black;
        }

        a.message:hover {
            color: green !important;
        }

        a.facebook:hover {
            color: #315aaf !important;
        }
        a.instagram:hover {
            color: #bc2a8d !important;
        }

        .copyright {
            border-top: solid 2px f.palette(border);
            list-style: none;
            margin: f.size(element-margin) * 2 0 f.size(element-margin) 0;
            padding: f.size(element-margin) 0 0 0;
            width: 100%;

            li {
                border-left: solid 2px var(--colour-footer-copyright);
                color: var(--colour-footer-copyright);
                display: inline-block;
                font-size: 0.9em;
                line-height: 1;
                margin-left: 1em;
                padding: 0 0 0 1em;

                &:first-child {
                    border-left: 0;
                    margin-left: 0;
                    padding-left: 0;
                }

                a {
                    color: var(--colour-footer-copyright);
                    border-bottom: none;
                }

                a:hover {
                    color: var(--colour-footer-copyright-hover) !important;
                }
            }
        }
    }

    @include b.breakpoint('<=large') {
        background-color: f.palette(bg);
        background-image: linear-gradient(to top, transparentize(f.palette(bg), 0.2), transparentize(f.palette(bg), 0.2)), url('../../images/bg.jpg');
        background-size: auto, cover;
        background-position: center, center;
        margin-top: f.size(wrapper-edges, large) * -1;
        padding-top: f.size(wrapper-edges, large);
    }

    @include b.breakpoint('<=medium') {
        margin-top: f.size(wrapper-edges, medium) * -1;
        padding-top: f.size(wrapper-edges, medium);

        .inner {
            @include m.padding(3em, 3em);
            display: block;
            width: 100%;

            form {
                width: 100%;
                margin: 0 0 f.size(element-margin) * 2 0;
            }

            .contact {
                width: 100%;
                margin: 0 0 f.size(element-margin) * 2 0;
            }

            .copyright {
                margin: f.size(element-margin) * 2 0 f.size(element-margin) 0;
            }
        }
    }

    @include b.breakpoint('<=small') {
        margin-top: f.size(wrapper-edges, small) * -1;
        padding-top: f.size(wrapper-edges, small);

        .inner {
            @include m.padding(2em, 2em);

            form {
                margin: 0 0 f.size(element-margin) * 1.5 0;
            }

            .contact {
                margin: 0 0 f.size(element-margin) * 1.5 0;
            }
        }
    }

    @include b.breakpoint('<=xsmall') {
        .inner {
            .copyright {
                li {
                    border-left: 0;
                    display: block;
                    margin: 1em 0 0 0;
                    padding-left: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @include b.breakpoint('<=xxsmall') {
        .inner {
            @include m.padding(2em, 1.5em);
        }
    }
}